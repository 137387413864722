const Footer = () => {
  return (
    <footer className="border-t border-greyBorder py-3">
      <div className="container">
        <div className="flex items-center justify-between">
          <h5 className="text-sm font-nunito-medium text-black tracking-tight">
            © 2023 Algosquant. All rights reserved.
          </h5>
          <h5 className="text-sm font-nunito-medium text-black tracking-tight">
            Designed by{" "}
            <a
              href="https://www.roundtechsquare.com"
              target="_blank"
              className="text-[#086AD8]"
              rel="noreferrer"
            >
              RoundTechSquare
            </a>
          </h5>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

/* PAGES */
import Login from "../pages/login";
import Dashboard from "../pages/dashboard";
import Profile from "../pages/profile";
import Reports from "../pages/reports";

/* LAYOUT */
import Layout from "../layout";

/* STYLES */
import "../styles/global.css";

/* TOAST IMPORT */
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

/* SCROLL TO TOP */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  const { loggedIn } = useSelector((state) => state.user);

  return (
    <div className='app'>
      <Router>
        <ScrollToTop />
        <ToastContainer />
        <Routes>
          <Route path='/' element={<Login />} />
          {loggedIn ? (
            <>
              {" "}
              <Route
                path='/dashboard'
                element={
                  <Layout>
                    <Dashboard />
                  </Layout>
                }
              />
              <Route
                path='/profile'
                element={
                  <Layout>
                    <Profile />
                  </Layout>
                }
              />
              <Route
                path='/reports'
                element={
                  <Layout>
                    <Reports />
                  </Layout>
                }
              />
            </>
          ) : (
            ""
          )}
        </Routes>
      </Router>
    </div>
  );
};

export default App;

import request from "./request";

// AUTH
export const login = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/auth/login", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const register = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/auth/register", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// PROFILE
export const getProfile = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/profile")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const updateProfile = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("/profile", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const updatePassword = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("/auth/password", data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const addNewExchangeAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    await request.post("/exchange", data).then((res) => resolve(res)).catch((e) => reject(e));
  })
}

export const getExchangeAPI = () => {
  return new Promise(async (resolve, reject) => {
    await request.get("/exchange").then((res) => resolve(res)).catch((e) => reject(e));
  })
}

export const updateExchangeAPI = (id, data) => {
  return new Promise(async (resolve, reject) => {
    await request.post(`/exchange/${id}`, data).then((res) => resolve(res)).catch((e) => reject(e));
  })
}

export const getTransactionsAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    await request.get(`/transaction`, { params: data }).then((res) => resolve(res)).catch((e) => reject(e));
  })
}

export const getUserTransactionGraph = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/transaction/graph", { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Select from "react-select";

/* MATERIAL TAILWLIND COMPONENTS */
import { Card, CardBody, Button, Spinner } from "@material-tailwind/react";

/* ICONS */
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

/* ASSETS */
import ShoonyaLogo from "../../assets/images/shoonya-logo.webp";
import { addNewExchangeAPI, getExchangeAPI, getProfile, updatePassword, updateProfile, updateExchangeAPI } from "../../service/api";
import { toast } from "react-toastify";
import actions from "../../redux/user/actions";
import { useDispatch } from "react-redux";

import { useFormik } from "formik";
import * as yup from "yup";


/* VALIDATION SCHEMA */
const validationSchema = yup.object().shape({
  userId: yup.string().required("Please provide your User Id"),
  password: yup.string().required("Please provide your Password"),
  twoFA: yup.string().required("Please provide your 2 FA"),
  imei: yup.string().required("Please provide your IMEI"),
  vendorCode: yup.string().required("Please provide the Vendor Code"),
  ApiSecret: yup.string().required("Please provide the API Secret"),
});

const exchanges = [
  { value: "CDS(INR)", label: "CDS(INR)" },
  { value: "MCX(INR)", label: "MCX(INR)" },
  { value: "NSE(INR)", label: "NSE(INR)" },
  { value: "NFO(INR)", label: "NFO(INR)" },
];

const Profile = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
  });
  const [oldPassword, setOldPassword] = useState(false);
  const [oldPasswordData, setOldPasswordData] = useState("");
  const [newPassword, setNewPassword] = useState(false);
  const [newPasswordData, setNewPasswordData] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [confirmPasswordData, setConfirmPasswordData] = useState("");
  const [brokerPassword, setBrokerPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPassword, setLoadingPassword] = useState(false);

  const [brokerDetails, setBrokerDetails] = useState(null);

  const [formSubmit, setFormSubmit] = useState(false);

  const profileData = () => {
    getProfile()
      .then((res) => {
        console.log("res==>", res);
        setUser({
          ...user,
          email: res?.data?.email,
          firstName: res?.data?.firstName,
          lastName: res?.data?.lastName,
          phone: res?.data?.phone,
        });
      })
      .catch((e) => {
        console.log("e==>", e);
      });
  };

  const fetchBrokerDataFunction = () => {
    getExchangeAPI()
      .then((res) => {
        console.log("res==>", res);
        setBrokerDetails(res?.data?.data)
      })
      .catch((e) => {
        console.log("e==>", e);
      });
  };

  /* UPDATE PROFILE FUNCTION */
  const profileUpdate = () => {
    const formData = new FormData();
    formData.append("firstName", user.firstName);
    formData.append("lastName", user.lastName);
    formData.append("email", user.email);
    formData.append("phone", user.phone);
    formData.append("twoFactorAuthentication", true);
    setLoading(true);
    updateProfile(formData)
      .then((res) => {
        toast.success(res?.message);
        profileData();
        dispatch(actions.setUser(res?.data));
        setLoading(false);
      })
      .catch((e) => {
        console.log("e==>", e);
        toast.error(e?.message);
        setLoading(false);
      });
  };

  /* UPDATE PASSWORD FUNCTION */
  const passwordUpdate = () => {
    if (oldPasswordData === "") {
      return toast.error("Please enter old password");
    }
    if (newPasswordData === "") {
      return toast.error("Please enter new password");
    }
    if (confirmPasswordData === "") {
      return toast.error("Please enter confirm new password");
    }
    if (newPasswordData !== confirmPasswordData) {
      return toast.error("New password doesn't match");
    }
    if (oldPasswordData === newPasswordData) {
      return toast.error("New password can not be same as old password ");
    }
    const formData = new FormData();
    formData.append("oldPassword", oldPasswordData);
    formData.append("password", newPasswordData);
    setLoadingPassword(true);
    updatePassword(formData)
      .then((res) => {
        console.log("res==>", res);
        if (res?.status) {
          toast.success(res?.data?.message);
          setConfirmPasswordData("");
          setNewPasswordData("");
          setOldPasswordData("");
          setLoadingPassword(false);
        } else {
          toast.error(res?.data?.message);
          setLoadingPassword(false);
        }
      })
      .catch((e) => {
        console.log("e==>", e);
        setLoadingPassword(false);
        toast.error(e?.response?.data?.message);
      });
  };

  const addExchangeFormik = useFormik({
    initialValues: {
      userId: "",
      password: "",
      twoFA: "",
      imei: "",
      vendorCode: "",
      ApiSecret: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("The submitted values are: ", values);
      setFormSubmit(true);

      if (brokerDetails?.[0]?._id) {
        updateExchangeAPI(brokerDetails?.[0]?._id, values)
          .then((res) => {
            console.log("res", res);
            if (res?.status) {
              toast.success(res?.data?.message)
            } else {
              toast.error(res?.data?.message)
            }
          })
          .catch((e) => {
            console.log(e)
            toast.error(e?.response?.data?.message)
          }).finally(() => {
            addExchangeFormik.handleReset();
            // window.location.reload()
            setFormSubmit(false);
          })
      } else {
        addNewExchangeAPI(values)
          .then((res) => {
            console.log("res", res);
            if (res?.status) {
              toast.success(res?.data?.message)
            } else {
              toast.error(res?.data?.message)
            }
          })
          .catch((e) => {
            console.log(e)
            toast.error(e?.response?.data?.message)
          }).finally(() => {
            addExchangeFormik.handleReset();
            // window.location.reload()
            setFormSubmit(false);
          })
      }

    },
  });


  useEffect(() => {
    profileData();
    fetchBrokerDataFunction()
  }, []);

  useEffect(() => {
    if (brokerDetails?.length > 0) {
      console.log("Brokerdetails", brokerDetails)
      addExchangeFormik.setFieldValue("userId", brokerDetails?.[0]?.userId)
      addExchangeFormik.setFieldValue("password", brokerDetails?.[0]?.password)
      addExchangeFormik.setFieldValue("twoFA", brokerDetails?.[0]?.twoFA)
      addExchangeFormik.setFieldValue("imei", brokerDetails?.[0]?.imei)
      addExchangeFormik.setFieldValue("vendorCode", brokerDetails?.[0]?.vendorCode)
      addExchangeFormik.setFieldValue("ApiSecret", brokerDetails?.[0]?.ApiSecret)
    }
  }, [brokerDetails])

  return (
    <section className="py-6 xl:py-12 lg:py-12">
      <div className="container">
        {/* GREETING */}
        <div>
          <h1 className="text-3xl font-nunito-bold text-textBlack tracking-tight">
            Your profile
          </h1>
          <p className="text-base font-nunito-regular tracking-tight text-greyText">
            Manage your account and broker connections.
          </p>
        </div>

        {/* PERSONAL DETAILS */}
        <div className="pt-6 xl:pt-12 lg:pt-12">
          <Card className="bg-white border border-cardBorder rounded-lg shadow-md">
            <CardBody className="p-0">
              <div className="px-5 py-3 border-b border-cardBorder flex items-center justify-between">
                <h5 className="text-lg font-nunito-bold text-black tracking-tight">
                  Personal Details
                </h5>
                <div className="flex items-center gap-2">
                  <Button
                    onClick={profileUpdate}
                    disabled={loading}
                    className="shadow-none hover:shadow-none bg-header text-sm font-nunito-medium text-white tracking-tight px-5 py-2 normal-case flex-shrink-0"
                  >
                    {loading ? (
                      <div className="flex center justify-center">
                        <Spinner className="h-[20px]" />
                      </div>
                    ) : (
                      " Edit details"
                    )}
                  </Button>
                </div>
              </div>
              <div className="p-5">
                <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5">
                  <div className="col-span-1">
                    <div className="form-group">
                      <label
                        htmlFor="firstName"
                        className="text-sm font-nunito-medium tracking-tight"
                      >
                        First name
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                        placeholder="First name"
                        value={user.firstName}
                        onChange={(e) =>
                          setUser({ ...user, firstName: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="form-group">
                      <label
                        htmlFor="lastName"
                        className="text-sm font-nunito-medium tracking-tight"
                      >
                        Last name
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                        placeholder="Last name"
                        value={user.lastName}
                        onChange={(e) =>
                          setUser({ ...user, lastName: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="form-group">
                      <label
                        htmlFor="email"
                        className="text-sm font-nunito-medium tracking-tight"
                      >
                        Email address
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="mt-1 w-full block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                        placeholder="Email address"
                        value={user.email}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="form-group">
                      <label
                        htmlFor="phone"
                        className="text-sm font-nunito-medium tracking-tight"
                      >
                        Phone
                      </label>
                      <input
                        type="number"
                        name="phone"
                        id="phone"
                        maxLength={10}
                        className="mt-1 w-full block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                        placeholder="Phone number"
                        value={user.phone}
                        onChange={(e) =>
                          setUser({ ...user, phone: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5 mt-5">
                  <div className="col-span-1">
                    <div className="form-group">
                      <label
                        htmlFor="oldPassword"
                        className="text-sm font-nunito-medium tracking-tight"
                      >
                        Old Password
                      </label>
                      <div className="relative">
                        <input
                          type={oldPassword ? "text" : "password"}
                          name="oldPassword"
                          id="oldPassword"
                          className="mt-1 w-full block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="Old password"
                          value={oldPasswordData}
                          onChange={(e) => setOldPasswordData(e.target.value)}
                        />
                        <Button
                          className="p-2 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5 bg-transparent !absolute top-[0.1vh] right-1"
                          onClick={() => setOldPassword(!oldPassword)}
                        >
                          {oldPassword ? (
                            <IoMdEyeOff className="w-5 h-5 text-greyText" />
                          ) : (
                            <IoMdEye className="w-5 h-5 text-greyText" />
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="form-group">
                      <label
                        htmlFor="newPassword"
                        className="text-sm font-nunito-medium tracking-tight"
                      >
                        New Password
                      </label>
                      <div className="relative">
                        <input
                          type={newPassword ? "text" : "password"}
                          name="newPassword"
                          id="newPassword"
                          className="mt-1 w-full block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="New password"
                          value={newPasswordData}
                          onChange={(e) => setNewPasswordData(e.target.value)}
                        />
                        <Button
                          className="p-2 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5 bg-transparent !absolute top-[0.1vh] right-1"
                          onClick={() => setNewPassword(!newPassword)}
                        >
                          {newPassword ? (
                            <IoMdEyeOff className="w-5 h-5 text-greyText" />
                          ) : (
                            <IoMdEye className="w-5 h-5 text-greyText" />
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="form-group">
                      <label
                        htmlFor="confirmPassword"
                        className="text-sm font-nunito-medium tracking-tight"
                      >
                        Confirm password
                      </label>
                      <div className="relative">
                        <input
                          type={confirmPassword ? "text" : "password"}
                          name="confirmPassword"
                          id="confirmPassword"
                          className="mt-1 w-full block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="Confirm new password"
                          value={confirmPasswordData}
                          onChange={(e) =>
                            setConfirmPasswordData(e.target.value)
                          }
                        />
                        <Button
                          className="p-2 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5 bg-transparent !absolute top-[0.1vh] right-1"
                          onClick={() => setConfirmPassword(!confirmPassword)}
                        >
                          {confirmPassword ? (
                            <IoMdEyeOff className="w-5 h-5 text-greyText" />
                          ) : (
                            <IoMdEye className="w-5 h-5 text-greyText" />
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center mb-5 items-center gap-2">
                <Button
                  onClick={passwordUpdate}
                  disabled={loadingPassword}
                  className="shadow-none hover:shadow-none bg-header text-sm font-nunito-medium text-white tracking-tight px-5 py-2 normal-case flex-shrink-0"
                >
                  {loadingPassword ? (
                    <div className="flex center justify-center">
                      <Spinner className="h-[20px]" />
                    </div>
                  ) : (
                    " Change password"
                  )}
                </Button>
              </div>
            </CardBody>
          </Card>
        </div>

        {/* BROKER CONNECTIONS */}
        <div className="pt-6 xl:pt-12 lg:pt-12">
          <Card className="bg-white border border-cardBorder rounded-lg shadow-md">
            <CardBody className="p-0">
              <div className="px-5 py-3 border-b border-cardBorder flex items-center justify-between">
                <h5 className="text-lg font-nunito-bold text-black tracking-tight">
                  Broker Connection
                </h5>
                {brokerDetails?.length > 0 && <div className="bg-green-500 rounded-md text-base font-nunito-bold text-white tracking-tight px-5 py-2">
                  Connected
                </div>}
                {/* <div className="bg-green-500 rounded-md text-base font-nunito-bold text-white tracking-tight px-5 py-2">
                  Connected
                </div> */}
              </div>
              <div className="p-5">
                <form onSubmit={addExchangeFormik.handleSubmit}>
                  <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 items-center">


                    <div className="col-span-3">
                      <div className="w-full border-none xl:border-r lg:border-r border-cardBorder pr-0 xl:pr-5 lg:pr-5">
                        <div className="bg-[#2E2E2E] rounded-md p-2 w-fit">
                          <img
                            src={ShoonyaLogo}
                            className="h-[5vh] w-auto object-cover"
                            alt="Shoonya by Finvasia"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-span-3 pl-0 xl:p-5 lg:p-5 mt-5 xl:pt-3 lg:pt-3 border border-cardBorder">
                      <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                        {/* USER ID */}
                        <div className="col-span-1">
                          <div className="form-group">
                            <label
                              htmlFor="userID"
                              className="text-sm font-nunito-medium tracking-tight"
                            >
                              User ID <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="text"
                              name="userId"
                              id="userId"
                              className="mt-1 w-full  block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                              placeholder="Shoonya User ID"
                              onChange={addExchangeFormik.handleChange}
                              value={addExchangeFormik.values.userId}
                            />
                            {addExchangeFormik.errors.userId && addExchangeFormik.touched.userId ? (
                              <small className="validation-text text-red-500">
                                {addExchangeFormik.errors.userId}
                              </small>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>

                        {/* PASSWORD */}
                        <div className="col-span-1">
                          <div className="form-group">
                            <label
                              htmlFor="userID"
                              className="text-sm font-nunito-medium tracking-tight"
                            >
                              Password <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="password"
                              name="password"
                              id="password"
                              className="mt-1 w-full  block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                              placeholder="Shoonya Password"
                              onChange={addExchangeFormik.handleChange}
                              value={addExchangeFormik.values.password}
                            />
                            {addExchangeFormik.errors.password && addExchangeFormik.touched.password ? (
                              <small className="validation-text text-red-500">
                                {addExchangeFormik.errors.password}
                              </small>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>

                        {/* 2FA */}
                        <div className="col-span-1">
                          <div className="form-group">
                            <label
                              htmlFor="twoFA"
                              className="text-sm font-nunito-medium tracking-tight"
                            >
                              2 FA <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="text"
                              name="twoFA"
                              id="twoFA"
                              className="mt-1 w-full  block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                              placeholder="Shoonya 2FA"
                              onChange={addExchangeFormik.handleChange}
                              value={addExchangeFormik.values.twoFA}
                            />
                            {addExchangeFormik.errors.twoFA && addExchangeFormik.touched.twoFA ? (
                              <small className="validation-text text-red-500">
                                {addExchangeFormik.errors.twoFA}
                              </small>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>

                        {/* IMEI */}
                        <div className="col-span-1">
                          <div className="form-group">
                            <label
                              htmlFor="imei"
                              className="text-sm font-nunito-medium tracking-tight"
                            >
                              IMEI <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="text"
                              name="imei"
                              id="imei"
                              className="mt-1 w-full  block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                              placeholder="Shoonya IMEI"
                              onChange={addExchangeFormik.handleChange}
                              value={addExchangeFormik.values.imei}
                            />
                            {addExchangeFormik.errors.imei && addExchangeFormik.touched.imei ? (
                              <small className="validation-text text-red-500">
                                {addExchangeFormik.errors.imei}
                              </small>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>

                        {/* Vendor Code */}
                        <div className="col-span-1">
                          <div className="form-group">
                            <label
                              htmlFor="vendorCode"
                              className="text-sm font-nunito-medium tracking-tight"
                            >
                              Vendor Code <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="text"
                              name="vendorCode"
                              id="vendorCode"
                              className="mt-1 w-full  block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                              placeholder="Shoonya Vendor Code"
                              onChange={addExchangeFormik.handleChange}
                              value={addExchangeFormik.values.vendorCode}
                            />
                            {addExchangeFormik.errors.vendorCode && addExchangeFormik.touched.vendorCode ? (
                              <small className="validation-text text-red-500">
                                {addExchangeFormik.errors.vendorCode}
                              </small>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>

                        {/* API Secret */}
                        <div className="col-span-1">
                          <div className="form-group">
                            <label
                              htmlFor="ApiSecret"
                              className="text-sm font-nunito-medium tracking-tight"
                            >
                              API Secret <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="text"
                              name="ApiSecret"
                              id="ApiSecret"
                              className="mt-1 w-full  block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                              placeholder="Shoonya API Secret"
                              onChange={addExchangeFormik.handleChange}
                              value={addExchangeFormik.values.ApiSecret}
                            />
                            {addExchangeFormik.errors.ApiSecret && addExchangeFormik.touched.ApiSecret ? (
                              <small className="validation-text text-red-500">
                                {addExchangeFormik.errors.ApiSecret}
                              </small>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <Button
                        type="submit"
                        disabled={formSubmit}
                        className="shadow-none mt-3 hover:shadow-none bg-header text-sm font-nunito-medium text-white tracking-tight px-5 py-2 normal-case flex-shrink-0 float-end"
                      >
                        {formSubmit ? (
                          <div className="flex center justify-center">
                            <Spinner className="h-[20px]" />
                          </div>
                        ) : (
                          " Save details"
                        )}
                      </Button>

                      {/* <div className="mt-5">
                      <p className="text-base font-nunito-semibold text-black tracking-tight italic">
                        Finvasia expects the user to login here everyday to
                        renew their API token. Click on "Regenerate Token" icon
                        to generate the token. OTP/ TOTP needs to be updated in
                        2FA field
                      </p>
                    </div> */}
                    </div>
                  </div>
                </form>
                {/* <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 items-center">
                  <div className="col-span-1">
                    <div className="w-full border-none xl:border-r lg:border-r border-cardBorder pr-0 xl:pr-5 lg:pr-5">
                      <div className="bg-[#2E2E2E] rounded-md p-2 w-fit">
                        <img
                          src={ShoonyaLogo}
                          className="h-[5vh] w-auto object-cover"
                          alt="Shoonya by Finvasia"
                        />
                      </div>
                      <div className="mt-5">
                        <div className="form-group">
                          <label
                            htmlFor="exchanges"
                            className="text-sm font-nunito-medium tracking-tight"
                          >
                            Exchanges <span className="text-red-500">*</span>
                          </label>
                          <Select
                            options={exchanges}
                            className="react-select"
                            classNamePrefix="dropdown"
                            placeholder="Exchanges"
                            isMulti
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2 pl-0 xl:pl-5 lg:pl-5 mt-5 xl:mt-0 lg:mt-0">
                    <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                      <div className="col-span-1">
                        <div className="form-group">
                          <label
                            htmlFor="userID"
                            className="text-sm font-nunito-medium tracking-tight"
                          >
                            User ID <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="userID"
                            id="userID"
                            className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                            placeholder="Shoonya User ID"
                          />
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="form-group">
                          <label
                            htmlFor="loginPassword"
                            className="text-sm font-nunito-medium tracking-tight"
                          >
                            Login Password{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="relative">
                            <input
                              type={brokerPassword ? "text" : "password"}
                              name="oldPassword"
                              id="oldPassword"
                              className="mt-1 w-full block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                              placeholder="Login password"
                            />
                            <Button
                              className="p-2 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5 bg-transparent !absolute top-[0.1vh] right-1"
                              onClick={() => setBrokerPassword(!brokerPassword)}
                            >
                              {oldPassword ? (
                                <IoMdEyeOff className="w-5 h-5 text-greyText" />
                              ) : (
                                <IoMdEye className="w-5 h-5 text-greyText" />
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="form-group">
                          <label
                            htmlFor="2fa"
                            className="text-sm font-nunito-medium tracking-tight"
                          >
                            Two Factor Authentication (2FA){" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="number"
                            name="2fa"
                            id="2fa"
                            className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                            placeholder="Two factor authentication"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-5">
                      <p className="text-base font-nunito-semibold text-black tracking-tight italic">
                        Finvasia expects the user to login here everyday to
                        renew their API token. Click on "Regenerate Token" icon
                        to generate the token. OTP/ TOTP needs to be updated in
                        2FA field
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default Profile;

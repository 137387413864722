/* HEADER AND FOOTER */
import Header from "./header";
import Footer from "./footer";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div className="bg-body h-bodyHeight overflow-auto overflow-x-hidden hide-scrollbar">
        {children}
      </div>
      <Footer />
    </>
  );
};

export default Layout;

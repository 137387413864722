/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { DateRangePicker } from "react-date-range";
import DataTable from "react-data-table-component";
import moment from "moment";

/* MATERIAL TAILWLIND COMPONENTS */
import { Card, CardBody, Button } from "@material-tailwind/react";

/* ICONS */
import { BsDownload } from "react-icons/bs";

/* CHART */
import { NetProfitLossGraph } from "../../components/chart";

/* IMAGES */
import Logo from "../../assets/images/logo.webp";

/* DATA */
import tradeLogs from "../../data/trade-logs";
import { useSelector } from "react-redux";
import { getTransactionsAPI } from "../../service/api";

/* CLICK OUTSIDE HANDLER */
const useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);
    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const Dashboard = () => {
  const { user } = useSelector((state) => state.user);
  const [datepicker, setDatepicker] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("MM/DD/YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("MM/DD/YYYY")
  );


  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [transactionData, setTransactionData] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [getLoading, setGetLoading] = useState(false);

  const [totalPL, setTotalPL] = useState(0);

  let domNode = useClickOutside(() => {
    setDatepicker(false);
  });

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  /* STATS CARD */
  const StatsCard = (props) => {
    return (
      <Card className="bg-white border border-cardBorder shadow-md rounded-md relative">
        <CardBody className="p-5">
          <h5 className="text-greyText font-nunito-medium tracking-tight text-sm">
            {props.label}
          </h5>
          <h4 className="text-textBlack font-nunito-bold text-3xl leading-none mt-3 tracking-tight">
            {props.value}
          </h4>
        </CardBody>
        {props.status === "profit" && (
          <div className="w-full h-1 bg-green-500 rounded-b-md" />
        )}
        {props.status === "loss" && (
          <div className="w-full h-1 bg-red-500 rounded-b-md" />
        )}
        {props.status === "normal" && (
          <div className="w-full h-1 bg-white rounded-b-md" />
        )}
      </Card>
    );
  };

  /* USER COLUMNS */
  const columns = [
    {
      id: 1,
      width: "10vh",
      name: "Sr. No",
      selector: (row, index) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
          {index + 1}
        </span>
      ),
    },
    {
      id: 2,
      width: "25vh",
      name: "Ticker",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack font-nunito-bold">
          {row.tickerFullSymbol}
        </span>
      ),
    },
    {
      id: 3,
      name: "Entry",
      width: "20vh",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
          {moment(row?.entryTime).format("DD-MM-YYYY HH:mm:ss A")}
        </span>
      ),
    },
    {
      id: 4,
      name: "Exit",
      width: "20vh",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
          {row?.entryTime === row?.exitTime
            ? "-"
            : row?.exitTime === undefined
              ? "-"
              : moment(row?.exitTime).format("DD-MM-YYYY HH:mm:ss A")}
        </span>
      ),
    },
    {
      id: 5,
      name: "Quantity",
      width: "12vh",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
          {row?.quantity}
        </span>
      ),
    },
    {
      id: 6,
      name: "Entry Price",
      width: "12vh",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
          ₹{row?.price?.toFixed(2)}
        </span>
      ),
    },
    {
      id: 7,
      name: "Exit Price",
      width: "12vh",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
          {row?.exitPrice === undefined ? "-" : `₹` + row?.exitPrice}
        </span>
      ),
    },
    {
      id: 8,
      name: "Algo",
      selector: (row) => (
        <span className={`text-sm tracking-tight   font-nunito-bold`}>
          Algo {row.algo}
        </span>
      ),
    },
    {
      id: 9,
      name: "PNL",
      selector: (row) => (
        <span
          className={`text-sm tracking-tight ${parseFloat(row.profit) < 0 ? "text-red-500" : "text-green-500"
            }  font-nunito-bold`}
        >
          {row.entryTime === row.exitTime
            ? "-"
            : row?.profit === undefined
              ? "-"
              : (row?.profit < 0 ? "- " : "") +
              `₹` +
              Math.abs(row.profit).toFixed(2)}
        </span>
      ),
    },
  ];

  const handlePerPageChange = async (e) => {
    setSizePerPage(e)
  };
  const handlePageChange = async (e) => {
    setPage(e)
  };

  /* GET TRANSACTIONS FUNCTION */
  const fetchTransactionsDataFunction = () => {
    const obj = {
      page: page,
      sizePerPage: sizePerPage,
      pagination: true
    }

    getTransactionsAPI(obj)
      .then((res) => {
        console.log("res==>", res?.data?.data);
        setTransactionData(res?.data?.data?.docs)
        setTotalDocs(res?.data?.data?.totalDocs)
        setTotalPages(res?.data?.data?.totalPages)
      })
      .catch((e) => {
        console.log("e==>", e);
      });

    getTransactionsAPI({ pagination: false, isOpen: false })
      .then((res) => {
        console.log("res==>", res?.data?.data);
        // setTransactionData(res?.data?.data?.docs)
        // setTotalDocs(res?.data?.data?.totalDocs)
        // setTotalPages(res?.data?.data?.totalPages)

        let count = 0;
        res?.data?.data?.forEach((ele) => {
          if (ele.entryTime !== ele.exitTime) {
            if (ele.profit) {
              count = count + ele.profit;
            }
          }
        });
        setTotalPL(count);
      })
      .catch((e) => {
        console.log("e==>", e);
      });
  };


  useEffect(() => {
    fetchTransactionsDataFunction();
  }, [page, sizePerPage]);
  return (
    <section className="py-6 xl:py-12 lg:py-12">
      <div className="container">
        {/* GREETING */}
        <div>
          <h5 className="text-base font-nunito-regular tracking-tight text-greyText">
            Welcome
          </h5>
          <h1 className="text-3xl font-nunito-bold text-textBlack tracking-tight capitalize">
            {user?.firstName}&nbsp;
            {user?.lastName}
          </h1>
          <p className="text-base font-nunito-regular tracking-tight text-greyText">
            At a glance summary of your statistics and strategies.
          </p>
        </div>

        {/* STATISTICS */}
        <div className="pt-6 xl:pt-12 lg:pt-12">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
            <div className="col-span-1">
              <StatsCard
                label="Total capital"
                value="₹509,850.90"
                status="normal"
              />
            </div>
            <div className="col-span-1">
              <StatsCard
                label="Net Profit/Loss"
                value={"₹" + totalPL.toFixed(2)}
                status={totalPL.toFixed(2) > 0 ? "profit" : "loss"}
              />
            </div>
            <StatsCard
              label="Total trades executed"
              value={totalDocs}
            />
            {/* <div className="col-span-1">
                  <StatsCard
                    label="Active Straegy"
                    value="Trendmaster Pro"
                    status="normal"
                  />
                </div> */}
            <div className="col-span-1">
              <StatsCard
                label="Broker Status"
                value="Disconnected"
                status="loss"
              />
            </div>
          </div>
        </div>

        {/* ACTIVE STRATEGIES */}
        <div className="pt-6 xl:pt-12 lg:pt-12">
          <h2 className="text-2xl font-nunito-bold text-black tracking-tight">
            Portfolio Performance
          </h2>
          <div className="pt-5">
            <Card className="bg-white border border-cardBorder shadow-md rounded-lg">
              <CardBody className="p-0">
                <div className="p-5 border-b border-cardBorder">
                  {/* DETAILS */}
                  <div className="block xl:flex lg:flex items-center justify-between">
                    <div className="flex items-center gap-5">
                      {/* <div className="w-12 h-12 rounded-full bg-header bg-opacity-5 flex items-center justify-center">
                        <img
                          src={Logo}
                          className="w-6 h-6 object-cover"
                          alt="Algosquant"
                        />
                      </div> */}
                      {/* <h5 className="text-xl font-nunito-bold text-textBlack tracking-tight">
                        Trendmaster Pro
                      </h5> */}
                    </div>
                    <div className="block xl:flex lg:flex items-center gap-3 mt-5 xl:mt-0 lg:mt-0">
                      <Button className="w-full xl:w-auto lg:w-auto bg-white hover:bg-black hover:bg-opacity-5 border border-cardBorder flex items-center justify-center gap-2 shadow-none hover:shadow-none text-textBlack text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded">
                        <BsDownload className="w-4 h-4 text-black" />
                        Long term taxable profit
                      </Button>
                      <Button className="w-full xl:w-auto lg:w-auto bg-white hover:bg-black hover:bg-opacity-5 border border-cardBorder flex items-center justify-center gap-2 shadow-none hover:shadow-none text-textBlack text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded mt-2 xl:mt-0 lg:mt-0">
                        <BsDownload className="w-4 h-4 text-black" />
                        Short term taxable profit
                      </Button>
                      <Button className="w-full xl:w-auto lg:w-auto bg-white hover:bg-black hover:bg-opacity-5 border border-cardBorder flex items-center justify-center gap-2 shadow-none hover:shadow-none text-textBlack text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded mt-2 xl:mt-0 lg:mt-0">
                        <BsDownload className="w-4 h-4 text-black" />
                        Report
                      </Button>
                      <div className="h-full py-2 px-5 bg-green-500 bg-opacity-20 rounded-md mt-2 xl:mt-0 lg:mt-0">
                        <p className="text-sm font-nunito-semibold tracking-tight text-green-500 text-center">
                          Active
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* DEPLOYMENT DETAILS */}
                  <div className="bg-[#F5F5F5] border border-[#E5E5E5] rounded-lg px-5 py-3 mt-5">
                    <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5 items-center">
                      <div className="col-span-1 xl:col-span-3 lg:col-span-3">
                        <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5">
                          <div className="col-span-1 text-center xl:text-left lg:text-left">
                            <h5 className="text-sm font-nunito-regular text-black tracking-tight">
                              Deployed on
                              <span className="font-nunito-bold block mt-2 text-lg">
                                {/* 26 Dec, 2023 */}-
                              </span>
                            </h5>
                          </div>
                          <div className="col-span-1 text-center xl:text-left lg:text-left">
                            <h5 className="text-sm font-nunito-regular text-black tracking-tight">
                              Capital deployed
                              <span className="font-nunito-bold block mt-2 text-lg">
                                {/* ₹509,850.90 */} -
                              </span>
                            </h5>
                          </div>
                          <div className="col-span-1 text-center xl:text-left lg:text-left">
                            <h5 className="text-sm font-nunito-regular text-black tracking-tight">
                              No. of contracts
                              <span className="font-nunito-bold block mt-2 text-lg">
                                {/* 3 */} -
                              </span>
                            </h5>
                          </div>
                          {/* <div className="col-span-1 text-center xl:text-left lg:text-left">
                            <div className="relative leading-none">
                              <div ref={domNode} className="form-group">
                                <label className="text-sm font-nunito-regular tracking-tight text-black leading-none">
                                  Filter by date
                                </label>
                                <input
                                  type="text"
                                  name="dateRange"
                                  value={`${startDate} - ${endDate}`}
                                  onClick={() => setDatepicker(!datepicker)}
                                  className="mt-2 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                                  placeholder="Date range"
                                />
                              </div>
                              {datepicker && (
                                <div
                                  ref={domNode}
                                  className="absolute top-[8vh] bottom-0 transition-all duration-300 ease-linear z-50"
                                >
                                  <DateRangePicker
                                    ranges={state}

                                    dragSelectionEnabled
                                    showPreview
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    onChange={(e) => {
                                      setState([e?.selection])
                                      setEndDate(moment(new Date(e?.selection?.endDate)).format("MM/DD/YYYY"))
                                      setStartDate(moment(new Date(e?.selection?.startDate)).format("MM/DD/YYYY"))
                                      // setSelectionRange([e])
                                      // setStartDate(
                                      //   moment(e.selection.startDate).format(
                                      //     "MM/DD/YYYY"
                                      //   )
                                      // );
                                      // setEndDate(
                                      //   moment(e.selection.endDate).format(
                                      //     "MM/DD/YYYY"
                                      //   )
                                      // );
                                    }}
                                    className="shadow-xl font-nunito-medium rounded-xl border border-cardBorder cursor-pointer"
                                  />
                                </div>
                              )}
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="w-full rounded-lg bg-white border border-cardBorder px-5 py-3">
                          <h5 className="text-sm font-nunito-medium text-black tracking-tight leading-none">
                            Total PNL:{" "}
                            <span className="text-lg font-nunito-bold text-green-500 block mt-2">
                              ₹{totalPL.toFixed(2)}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-0">
                  {/* TRADE LOGS */}
                  <DataTable
                    columns={columns}
                    data={totalDocs > 0 ? transactionData : []}
                    className="datatable rounded-md"
                    progressPending={getLoading}

                    progressComponent={<div className="pagination-loading-screen">
                      <p>Please wait!</p>
                      <p>We are fetching data </p>
                    </div>}
                    pagination
                    paginationServer
                    paginationTotalRows={totalDocs}
                    noDataComponent={
                      <div className="rounded-b-md py-10">
                        <p className="text-sm font-nunito-medium tracking-tight text-textBlack">
                          There are no records to display.
                        </p>
                      </div>
                    }
                    onChangeRowsPerPage={handlePerPageChange}
                    onChangePage={handlePageChange}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;

import { Link, useNavigate } from "react-router-dom";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Popover,
  PopoverContent,
  PopoverHandler,
} from "@material-tailwind/react";

/* IMAGES */
import Logo from "../assets/images/logo.webp";
import actions from "../redux/user/actions";
import { useDispatch, useSelector } from "react-redux";

const Header = () => {
  const { user } = useSelector((state) => state.user);
  // console.log("%c Line:17 🍓 user", "color:#3f7cff", user);
  var pathname = window.location.pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    dispatch(actions.setUser(null));
    dispatch(actions.setLoggedIn(false));
    dispatch(actions.setToken(null));
    navigate("/");
  };

  return (
    <header className="bg-header border-b border-white border-opacity-10 py-2">
      <div className="container">
        <div className="flex items-center justify-between">
          {/* LEFT NAVIGATION */}
          <div className="flex items-center justify-between gap-5 xl:gap-16 lg:gap-16">
            {/* LOGO */}
            <Link to="/dashboard">
              <img src={Logo} alt="Algosquant" className="h-[5vh] w-auto" />
            </Link>

            {/* NAVIGATION OPTIONS */}
            <div className="flex items-center gap-5 xl:gap-8 lg:gap-8">
              <Link
                to="/dashboard"
                className={`block ${
                  pathname === "/dashboard" && "!text-white text-opacity-100"
                } text-white text-opacity-60 text-sm font-nunito-semibold hover:text-white tracking-tight transition-all duration-300 ease-in-out custom-underline`}
              >
                Dashboard
              </Link>
              <Link
                to="/reports"
                className={`block ${
                  pathname === "/reports" && "!text-white text-opacity-100"
                } text-white text-opacity-60 text-sm font-nunito-regular hover:text-white tracking-tight transition-all duration-300 ease-in-out custom-underline`}
              >
                Reports
              </Link>
            </div>
          </div>

          {/* RIGHT NAVIGATION */}
          <div className="flex items-center gap-10">
            <Popover placement="bottom-start">
              <PopoverHandler>
                <div className="flex items-center gap-3 hover:outline-none cursor-pointer">
                  <div className="w-10 h-10 bg-white border border-[#E5E5E5] rounded-full flex items-center justify-center">
                    <img
                      src={Logo}
                      className="w-7 h-7 object-contain rounded-full"
                      alt="User profile"
                    />
                  </div>
                  <div>
                    <h2 className="text-sm font-nunito-semibold text-white leading-none tracking-tight">
                      {user?.firstName}&nbsp;
                      {user?.lastName}
                    </h2>
                  </div>
                </div>
              </PopoverHandler>
              <PopoverContent className="w-[20vh] p-2 z-50">
                <Link to="/profile" className="block outline-none">
                  <div className="cursor-pointer px-4 py-2 text-sm tracking-tight font-nunito-medium hover:bg-black hover:text-black hover:bg-opacity-5 rounded-md transition-all duration-300 ease-in-out">
                    My Profile
                  </div>
                </Link>
                {/* <Link to="/" className="block outline-none"> */}
                <div
                  onClick={handleLogout}
                  className="cursor-pointer px-4 py-2 text-sm tracking-tight font-nunito-medium hover:bg-black hover:text-black hover:bg-opacity-5 rounded-md transition-all duration-300 ease-in-out"
                >
                  Logout
                </div>
                {/* </Link> */}
              </PopoverContent>
            </Popover>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
